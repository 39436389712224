import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {CookiesProvider, useCookies} from 'react-cookie'
import {loader}  from './game/loader';
import Auth from './Components/Auth'

import './App.css';
const center = [39.8265, 21.4240]
const zoomFact = 20
//const center = [148.9819, -35.081]
let _3D_ok = false

const Play = () =>{
  const [cookies] = useCookies(['user']);
  
  console.log("cookies", cookies)
  if(!cookies.user){ 
    window.history.pushState({}, null, "/auth");
    return <Auth />
  }
  else{ 
    if(!_3D_ok){ 
      loader(center, 0, [-Math.PI / 2, Math.PI / 2, 0], zoomFact)
      _3D_ok = true
    }
    return <CookiesProvider><div id="map"></div></CookiesProvider>
  }
}



function App() {
  //const a = process.env.REACT_APP_BASE_URL||'/'
 
  return (
    <div className="App">
      <CookiesProvider>
        <Router>
          <div>
            <Routes>
              <Route path="/" element={<Play/>} />
              <Route path="/play" element={<Play/>} />
              <Route path="/auth" element={<Auth />} />
      
            </Routes>
          </div>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;
