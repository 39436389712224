import {  put, call, all, fork, takeLatest } from 'redux-saga/effects';
import {LoginActionConst, LoginResultConst} from "../consts"
import {checkLogin} from "../services"


function* checkLoginSaga(action) {
    
    const result = yield call(checkLogin, action.email, action.password)
    
    yield put({ type: LoginResultConst, navigation: action.navigation, user: result.user});
}

function* actionWatcher() {
    yield takeLatest(LoginActionConst, checkLoginSaga)
}

export default function* rootSaga() {
    yield all([
        fork(actionWatcher)
    ]);
 }