import {LoginResultConst} from "../consts"


import Cookies from 'universal-cookie';

const reducer = (state = {}, action) => {
    const cookies = new Cookies();


    switch (action.type) {
        case LoginResultConst:
            if(action.user){
                cookies.set("user", action.user)
                if(action.navigation){
                    action.navigation("/")
                }
            }
            return  { ...state, login: {user: action.user}};
    }
}


export default reducer;