import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import * as THREE from 'three';
import React, { useState, useEffect } from "react";

const basUrl = process.env.REACT_APP_BASE_URL||'/'

export  const  loader = ( modelOrigin, modelAltitude, modelRotate, zoomFact) =>{
    setTimeout(()=>{
        const map = new window.maplibregl.Map({
            container: 'map',
            style:"./mapstyle.json",
            zoom: 12.15,
            minZoom: 5,
            maxZoom: 16,
            hash: true,
            center: modelOrigin,
            pitch: 45
        });

        map.on("terrain", (e)=>{
            console.log("terrain", e)
        })

        const modelAsMercatorCoordinate = window.maplibregl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );
        //modelAsMercatorCoordinate.z = 200
        // transformation parameters to position, rotate and scale the 3D model onto the map
        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            /* Since our 3D model is in real world meters, a scale transform needs to be
            * applied since the CustomLayerInterface expects units in MercatorCoordinates.
            */
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
        };
        

        const customLayer = {
            id: '3d-model',
            type: 'custom',
            renderingMode: '3d',
            meshs: {},
            onAdd (map, gl) {
                

                this.camera = new THREE.Camera();
                this.scene = new THREE.Scene();


                const scalable = new THREE.Matrix4()
                            .scale(
                                new THREE.Vector3(
                                    1 * zoomFact,
                                    -1 * zoomFact,
                                    1 * zoomFact
                                )
                            )

                const directionalLight = new THREE.DirectionalLight(0xffffff);
                directionalLight.position.set(0, -40, 30, 0.5).normalize();
                this.scene.add(directionalLight);

                const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                directionalLight2.position.set(0, 10, 0.3).normalize();
                this.scene.add(directionalLight2);

                const loader = new GLTFLoader();
                const self = this
                loader.load(

                    
                    //'https://maplibre.org/maplibre-gl-js/docs/assets/34M_17/34M_17.gltf',
                    `${basUrl}game/datas/models/tour.glb`,
                    (gltf) => {

                        gltf.scene.applyMatrix4(scalable)//scale = new THREE.Vector3(10, 10, 10)
                        this.scene.add(gltf.scene);

                        const cube = new THREE.BoxHelper(gltf.scene, 0xff0000);
                        cube.update();
                        
                        
 
                        
                        //const object = new THREE.Mesh( cube.geometry, new THREE.MeshBasicMaterial( {color: 0x00ff00} ) );
                        
                        this.scene.add(cube);


                        const clip = THREE.AnimationClip.findByName( gltf.animations, "Stop_01" )
                        const mixer = new THREE.AnimationMixer( gltf.scene );
                        const action = mixer.clipAction( clip )
                        //action.setLoop(10)
                        
                        self.meshs["camel"] = {
                            "mesh": gltf,
                            "currentAnimationName": "",
                            "mixer": mixer,
                            "action": action,
                            "clip": clip,
                            current: 0,
                            "currentAnimation": undefined
                        }
                        console.log("loader.onAdd", gltf)
                        if(action) action.play()
                        
                        gltf.animations.forEach( ( clip ) => {
          
                            //mixer.clipAction( clip ).play();
                          
                        } );
                    }
                );
                this.map = map;
                const canvas = map.getCanvas()
                console.log("loader.onAdd", modelAsMercatorCoordinate)
                // use the MapLibre GL JS map canvas for three.js
                this.renderer = new THREE.WebGLRenderer({
                    canvas: canvas,
                    context: gl,
                    antialias: true
                });

                this.renderer.autoClear = false;

            },
            render (gl, args) {
                const rotationX = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(1, 0, 0),
                    modelTransform.rotateX
                );
                const rotationY = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 1, 0),
                    modelTransform.rotateY
                );
                const rotationZ = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 0, 1),
                    modelTransform.rotateZ
                );

                const m = new THREE.Matrix4().fromArray(args.defaultProjectionData.mainMatrix);
                const l = new THREE.Matrix4()
                    .makeTranslation(
                        modelTransform.translateX,
                        modelTransform.translateY,
                        modelTransform.translateZ
                    )
                    .scale(
                        new THREE.Vector3(
                            modelTransform.scale,
                            -modelTransform.scale,
                            modelTransform.scale
                        )
                    )
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                // Alternatively, you can use this API to get the correct model matrix.
                // It will work regardless of current projection.
                // Also see the example "globe-3d-model.html".
                //
                // const modelMatrix = args.getMatrixForModel(modelOrigin, modelAltitude);
                // const m = new THREE.Matrix4().fromArray(matrix);
                // const l = new THREE.Matrix4().fromArray(modelMatrix);
                const self =this
                this.camera.projectionMatrix = m.multiply(l);
                this.renderer.resetState();
                
                
                this.map.triggerRepaint();
                

                this.renderer.setAnimationLoop(() => {
                    //console.log("setAnimationLoop")
                    self.renderer.render(this.scene, this.camera);
                    for( let a in self.meshs){
                        const m = self.meshs[a].mixer
                        if(m && self.meshs[a].clip){
                            self.meshs[a].current += 1
                            if(self.meshs[a].current>=self.meshs[a].clip.tracks.length){
                                self.meshs[a].current = 0
                                self.meshs[a].action.timeScale  = self.meshs[a].action.timeScale * -1
                                //console.log("action:", self.meshs[a].action)
                            }
                            m.update(1/60)
                        }
                        /*const mesh = self.meshs[a].mesh
                        if(mesh){ 
                            m.clipAction( self.meshs[a].clip ).play();
                            if( m ){
                                m.update(1/60)
                            }
                            const r = Math.random()
                            if(self.meshs[a].clip.name === "Idle_01" && r<0.001){
                                m.clipAction( self.meshs[a].clip ).stop();
                                self.meshs[a].clip = THREE.AnimationClip.findByName( mesh.animations, "Idle_02" )
                                console.log(" self.meshs[a].clip = ",  self.meshs[a].clip.name, r)
                            } else if(self.meshs[a].clip.name === "Idle_02" && r<0.01){
                                m.clipAction( self.meshs[a].clip ).stop();
                                self.meshs[a].clip = THREE.AnimationClip.findByName( mesh.animations, "Idle_01" )
                                console.log(" self.meshs[a].clip = ",  self.meshs[a].clip.name, r)
                            }
                        }*/
                    }

                    /*const camel = self.meshs["camel"]
                    if(camel){ 
                        const mixer = new THREE.AnimationMixer( self.meshs["camel"]["mesh"] );
                        if(!camel.currentAnimation ){
                            const clips = self.meshs["camel"].mesh.animations;
                            self.meshs["camel"]["currentAnimationName"] = "Idle_02";
                            const clip = THREE.AnimationClip.findByName( clips, self.meshs["camel"]["currentAnimationName"] );
                            self.meshs["camel"]["currentAnimation"] = {
                                action: mixer.clipAction( clip ),
                                mixer: mixer
                            }
                        }
                        else{ 
                            camel.currentAnimation.action.play();
                            mixer.update( 1/60 )

    
                        }
                    }*/
                })
            }
        }
        map.on('style.load', () => {
            /*map.setProjection({
                type: 'globe', // Set projection to globe
            })*/
            map.addLayer(customLayer);
            /*;*/
        });
    }, 10)
 }