import { connect } from 'react-redux'
import { AppProvider } from '@toolpad/core/AppProvider';
import { SignInPage} from '@toolpad/core/SignInPage';
import {CookiesProvider, useCookies} from 'react-cookie'
import {onLoginAction} from '../actions'
import { useNavigate } from "react-router-dom";
const providers = [
    //{ id: 'google', name: 'Google' },
    { id: 'credentials', name: 'Email and Password' },
    
  ];
  const signIn = async (provider, formData, navigate, props) =>  {
    
    if(provider.id == "google"){
        const authInstance = window.gapi.auth2;
        console.log(`Signing in with "${provider.name}, ${provider.id}`, authInstance)

    }
    else{ 
        //setCookie("user", "email")
        //console.log(`Signing in with "${provider.name}, ${provider.id}" and credentials: ${formData? formData.get('email'): "non"}, ${formData? formData.get('password'):"non"}`, window.gapi)
        props.onLoginAction(formData.get('email'), formData.get('password'), navigate)
        
    }
  }


  

let Auth = (props)=> {
    const [setCookie] = useCookies(['user']);
    const navigate = useNavigate()
    return <CookiesProvider>
    <AppProvider>
        <SignInPage signIn={(provider, formData)=>{signIn(provider, formData, navigate, props)}} providers={providers} slotProps={{ emailField: { autoFocus: false }, form: { noValidate: true } }} />
    </AppProvider></CookiesProvider>
  }


  const mapDispatchToProps = {
    onLoginAction: onLoginAction
}

const mapStateToProps = (state) => {  
    return {
        "login": state ? state.login : null
    }
}


Auth = connect(mapStateToProps, mapDispatchToProps)(Auth);

export default Auth;